<template>
  <div class="login_container">
    <RegisterInner></RegisterInner>
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import RegisterInner from "@/pages/login/register-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { RegisterInner },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
